import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// import Image from 'next/image';
import { format } from 'date-fns';
import Slider from "react-slick";
import { imgSrcHelper, isEmpty } from '@app/utils';
import { GalleryPopup, Video as VideoComponent, Button } from '@app/components';
import 'slick-carousel/slick/slick.css';

/**
* Image UI component
*/
const SingleImage = props => props.imagePath || props.alternateImagePath ?
  <figure>
    {/* <Image src={imgSrcHelper(props.imagePath || props.alternateImagePath, { h: 677, w: 1204, q: 100, c: 1, v: format(new Date(), 'yyyymmdd') })} layout='intrinsic' width='1204' height='677' alt={props.heading || undefined} className='tw-block tw-w-full tw-h-auto' /> */}
    <img src={imgSrcHelper(props.imagePath || props.alternateImagePath, { h: 677, w: 1204, q: 100, c: 1, v: format(new Date(), 'yyyymmdd') })} width='1204' height='677' alt={props.heading || undefined} className='tw-block tw-w-full tw-h-auto' />
  </figure>
  : null;

/**
* GallerySlider UI component
*/
const Gallery = props => {
  const [currentId, setCurrentId] = useState(props.currentId || -1);
  const sliderRef = useRef();
  const PrevArrow = props => <Button classes='tw-absolute tw-top-0 tw-z-10 tw-mt-28.125% tw-transform tw--translate-y-1/2 tw-font-icon tw-text-center tw-cursor-pointer tw-text-white tw-bg-primary-main | hover:tw-bg-primary-dark' btnType='boxIcon' size='md' title='navigate_before' onClick={props.onClick} />;
  const NextArrow = props => <Button classes='tw-absolute tw-top-0 tw-right-0 tw-z-10 tw-mt-28.125% tw-transform tw--translate-y-1/2 tw-font-icon tw-text-center tw-cursor-pointer tw-text-white tw-bg-primary-main | hover:tw-bg-primary-dark' btnType='boxIcon' size='md' title='navigate_next' onClick={props.onClick} />;

  const handleClick = id => () => {
    setCurrentId(id);
    sliderRef.current.slickPause();
  }

  const handleClose = id => () => {
    setCurrentId(-1);
    sliderRef.current.slickGoTo(id, true);
  }

  const setting = {
    dots: false,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    lazyload: 'progressively',
    prevArrow: <PrevArrow />,
    nextArrow: <NextArrow />,
    ...props.sliderSettings
  }

  return !isEmpty(props.images) ?
    <figure>
      <Slider ref={sliderRef} className='tw-mb-10' {...setting}>
        {props.images.map(image =>
          <div key={image.id} className='tw-relative tw-overflow-hidden'>
            <button className='tw-w-full' onClick={handleClick(image.id)}>
              {/* <Image src={imgSrcHelper(image.slug, { h: 677, w: 1204, q: 100, c: 1, v: format(new Date(), 'yyyymmdd') })} layout='intrinsic' width='1204' height='677' alt={image.display || undefined} className='tw-block tw-w-full tw-h-auto tw-border-0' /> */}
              <img src={imgSrcHelper(image.slug, { h: 677, w: 1204, q: 100, c: 1, v: format(new Date(), 'yyyymmdd') })} width='1204' height='677' alt={image.display || undefined} className='tw-block tw-w-full tw-h-auto tw-border-0' />
            </button>
            <div className='tw-font-body tw-italic tw-text-base tw-text-third-main tw-mt-2 tw-px-2 | sm:tw-px-0'>{image.display}</div>
          </div>
        )}
      </Slider>
      <GalleryPopup currentId={currentId} images={props.images} onClose={handleClose} />
    </figure >
    : null;
}

/**
* Video UI component
*/
const Video = props => props.video ? <VideoComponent {...props.video} type='head' /> : null;

/**
* Single Article Image UI component
*/
export const ArticleImage = props => {
  switch (props.itemType) {
    case 'article':
      return <SingleImage {...props} /> 

    case 'gallery':
      switch (props.lockReason) {
        case 'NotLocked':
        case 'Trialing':
          return <Gallery {...props} />
        default:
          return <SingleImage {...props} />
      }
    case 'video':
      switch (props.lockReason) {
        case 'NotLocked':
        case 'Trialing':
          return <Video {...props} />
        default:
          return <SingleImage {...props} />
      }
    default:
      return null;
  }
}

export default ArticleImage;

ArticleImage.propTypes = {
  /**
  * Heading of the article summary
  */
  heading: PropTypes.string,
  /**
  * Type of the article ('article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine')
  */
  itemType: PropTypes.oneOf(['article', 'gallery', 'video', 'event', 'webinar', 'link', 'topic', 'magazine']).isRequired,
  /**
  * Article image path
  */
  imagePath: PropTypes.string,
  /**
  * Alternate image path of the article summary
  */
  alternateImagePath: PropTypes.string,
  /**
  * Current id for gallery slide
  */
  currentId: PropTypes.number,
  /**
  * Gallery images array
  */
  images: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    slug: PropTypes.string,
    display: PropTypes.string
  })),
  /**
  * Gallery slider settings reference: https://react-slick.neostack.com/docs/api
  */
  sliderSettings: PropTypes.object,
  /**
  * Video data and information
  */
  video: PropTypes.shape({
    id: PropTypes.string.isRequired,
    player: PropTypes.string.isRequired
  }),
  /**
  * Source string for gallery or video
  */
  source: PropTypes.string,
  /**
  * Lock reason from 
  */
  lockReason: PropTypes.oneOf(['NotLocked', 'Trialing', 'TrialEnded', 'NoSubscription', 'DomainBlacklisted']).isRequired,
};

ArticleImage.defaultProps = {
  itemType: 'article'
};